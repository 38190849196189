export const pageMeta = {
    'HomeView': {
        title: 'Home',
        description: 'Home description',
        keywords: 'Description, keywords',
        image: 'https://www.we-nocode.com/logoNoCode.ico',
        url:'https://www.we-nocode.com/blog'
    },
    'ServicesView': {
        title: 'Services',
        description: 'Services description',
        keywords: 'Description, keywords',
    },
    'ShowcaseView': {
        title: 'Showcase',
        description: 'Showcase description',
        keywords: 'Description, keywords',
    },
    'PossibilitiesView': {
        title: 'Possibilities',
        description: 'Possibilities description',
        keywords: 'Description, keywords',
    },
    'DetailsView': {
        title: 'Details',
        description: 'Details description',
        keywords: 'Description, keywords',
    },
    'BlogView': {
        title: 'Blog',
        description: 'Blog description',
        keywords: 'Description, keywords',
    },
    'ContactView': {
        title: 'Contact',
        description: 'Contact description',
        keywords: 'Description, keywords',
    },
    'ProjectView': {
        title: 'Project',
        description: 'Project description',
        keywords: 'Description, keywords',
    },
    'NotFoundView': {
        title: 'Home',
        description: 'Home description',
        keywords: 'Description, keywords',
    },
};