import React, { useState, useRef } from "react";
import "../СustomSelect/CustomSelect.css";

export default function CustomSelect({ value, onChange, options }) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleSelect = (val) => {
    onChange(val);
    setIsOpen(false);
  };

  return (
    <div
      className="custom-select"
      ref={selectRef}
      tabIndex={0}
      onBlur={() => setIsOpen(false)}
    >
      <div className="custom-select__selected" onClick={() => setIsOpen(!isOpen)}>
        {value ? options.find((opt) => opt.value === value)?.label : "Where did you hear about us?"}
        <span className={`custom-select__arrow ${isOpen ? "open" : ""}`} />
      </div>
      {isOpen && (
        <ul className="custom-select__dropdown">
          {options
            .filter((opt) => opt.value !== value)
            .map((opt) => (
              <li
                key={opt.value}
                className="custom-select__option"
                onClick={() => handleSelect(opt.value)}
              >
                {opt.label}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
