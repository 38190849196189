import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    windowInnerWidth: window.innerWidth,
    servicesBlockScroll: '',
    isOpenMenu: false,
    isOpenFilterMenu: false,
    selectedDirections: {},
    selectedTechLevels: {},
    showMessageObj: {
        open: false,
        message: '',
        status: '',
    },
}

export const homeSlice = createSlice({
    name: 'homeSlice',
    initialState,
    reducers: {
        setShowMessageObj: (state, action) => {
            state.showMessageObj = action.payload;
        },
        setWindowInnerWidth: (state, action) => {
            state.windowInnerWidth = action.payload;
        },
        setServicesBlockScroll: (state, action) => {
            state.servicesBlockScroll = action.payload;
        },
        setIsOpenMenu: (state, action) => {
            state.isOpenMenu = action.payload;
        },
        setIsOpenFilterMenu: (state, action) => {
            state.isOpenFilterMenu = action.payload;
        },
        setSelectedDirections: (state, action) => {
            state.selectedDirections = action.payload;
        },
        setSelectedTechLevels: (state, action) => {
            state.selectedTechLevels = action.payload;
        },
    },
})

export const { setIsOpenMenu, setIsOpenFilterMenu, setServicesBlockScroll, setWindowInnerWidth, setShowMessageObj, setSelectedDirections, setSelectedTechLevels } = homeSlice.actions

export default homeSlice.reducer