import React, { useEffect, useState } from "react";
import "./ApplyForm.css";
import addIcon from "../../assets/addIcon.svg";
import { validEmail } from "../../helpers/Utils";
import CustomSelect from "../СustomSelect/CustomSelect";
import ResponseModal from "../ResponseModal/ResponseModal";
import { sendApplyForm } from "../../services/possibilities";

export default function ApplyForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resumeCv, setResumeCv] = useState(null);
  const [source, setSource] = useState("");
  const [comment, setComment] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const options = [
    { value: "google", label: "Google" },
    { value: "social_media", label: "Social Media" },
    { value: "friend", label: "Friend" },
    { value: "advertisement", label: "Advertisement" },
    { value: "other", label: "Other" }
  ];

  useEffect(() => {
    setErrorMessage("");
    setErrorFirstName("");
    setErrorLastName("");
    setErrorEmail("");
  }, [isChecked, firstName, lastName, email]);

  const handleChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.startsWith("380")) {
      value = value.slice(3);
    }
    setPhone(value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setResumeCv(file);
    }
  };

  const handleApply = async () => {
    let isValid = true;
  
    if (!firstName) {
      setErrorFirstName("First name is required");
      isValid = false;
    }
  
    if (!lastName) {
      setErrorLastName("Last name is required");
      isValid = false;
    }
  
    if (!email) {
      setErrorEmail("Email is required");
      isValid = false;
    } else if (!validEmail(email)) {
      setErrorEmail("Please enter a valid email");
      isValid = false;
    }
  
    if (!isChecked) {
      setErrorMessage("You must accept the terms and conditions");
      isValid = false;
    }
  
    if (isValid) {
      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("source", source);
      formData.append("comment", comment);
  
      if (resumeCv) {
        formData.append("resume", resumeCv); 
      }
      setIsModalOpen(true);
      // let res = await sendApplyForm(formData);
      // if (res?._id) {
        
      // } else {
      //   console.log("Error sending form");
      // }
    }
  };
  

  return (
    <div className="apply-form-wrap">
      <ResponseModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <h2 className="apply-form__title">Apply here</h2>

      <div className="apply-form">
        <div className="apply-form__text-wrap">
          <p className="apply-form__text">First name*</p>
          {errorFirstName && (
            <p className="apply-form__text error-message">{errorFirstName}</p>
          )}
        </div>
        <input
          type="text"
          className={`apply-form__input ${errorFirstName ? "error-input" : ""}`}
          placeholder="John"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
        />
      </div>

      <div className="apply-form">
        <div className="apply-form__text-wrap">
          <p className="apply-form__text">Last name*</p>
          {errorLastName && (
            <p className="apply-form__text error-message">{errorLastName}</p>
          )}
        </div>
        <input
          type="text"
          className={`apply-form__input ${errorLastName ? "error-input" : ""}`}
          placeholder="Smith"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
        />
      </div>

      <div className="apply-form">
        <div className="apply-form__text-wrap">
          <p className="apply-form__text">Email*</p>
          {errorEmail && (
            <p className="apply-form__text error-message">{errorEmail}</p>
          )}
        </div>
        <input
          type="text"
          className={`apply-form__input ${errorEmail ? "error-input" : ""}`}
          placeholder="johnsmith@gmail.com"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </div>

      <div className="apply-form">
        <p className="apply-form__text">Phone</p>
        <input
          type="text"
          className="apply-form__input"
          placeholder="00 000 00 00"
          onChange={handleChange}
          value={`+380 | ${phone}`}
        />
      </div>

      <div className="apply-form">
        <p className="apply-form__text">Resume/CV</p>
        <label className="apply-form__label">
          {resumeCv ? (
            <p className="apply-form__file-name">{resumeCv.name}</p>
          ) : (
            <span className="apply-form__placeholder">Attach resume/CV</span>
          )}
          <input
            type="file"
            className="apply-form__input-file"
            onChange={handleFileChange}
          />
          <span className="apply-form__arrow">
            <img src={addIcon} alt="addIcon" />
          </span>
        </label>
      </div>

      <div className="apply-form">
        <p className="apply-form__text">Where did you hear about us?</p>
        <CustomSelect value={source} onChange={(val) => setSource(val)} options={options} />
      </div>

      <div className="apply-form">
        <p className="apply-form__text">Additional comment</p>
        <textarea
          type="text"
          className="apply-form__textarea"
          placeholder="Smth important for you"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </div>

      <div className="apply-form-checkbox">
        <input
          type="checkbox"
          id="apply-checkbox"
          className="apply-form-checkbox__input"
          onChange={() => setIsChecked(!isChecked)}
        />
        <label
          htmlFor="apply-checkbox"
          className="apply-form-checkbox__label"
        ></label>
        <p className="apply-form-checkbox__text">
          *I have read and accepted the <a href="#">TERMS AND CONDITIONS</a> and{" "}
          <a href="#"> Privacy Policy</a>.
        </p>
      </div>

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <button className="apply-form-btn" onClick={handleApply}>
        Apply
      </button>
    </div>
  );
}
