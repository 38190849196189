import './HomeView.css';
import {
    pageMeta
} from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import FormBlock from '../../components/FormBlock/FormBlock';
import restackImg from '../../assets/restackImg.svg';
import payoneerImg from '../../assets/payoneerImg.svg';
import bsbrImg from '../../assets/bsbrImg.svg';
import hebronImg from '../../assets/hebronImg.svg';
import clutchImg from '../../assets/clutchImg.svg';
import pLogoImg from '../../assets/pLogoImg.svg';
import softwareImg from '../../assets/softwareImg.svg';
import mainflowImg from '../../assets/mainflowImg.svg';
import artMasherDesImg from '../../assets/artMasherDesImg.png';
import vulcanDesImg from '../../assets/vulcanDesImg.png'
import vyrobyDesImg from '../../assets/vyrobyDesImg.png'
import proCoachDesImg from '../../assets/proCoachDesImg.png'
import AccordionBlock from '../../components/AccordionBlock/AccordionBlock';
import {
    AINTELLIGENCE,
    ARTMASHER,
    BUSINESS,
    CYBERSECURITY,
    DESIGN,
    PROCOACH,
    SERVICES,
    SOFTWARE,
    VYROBY,
    VULCAN
} from '../../helpers/Config';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

function HomeView() {
    const windowInnerWidth = useSelector(state => state.homeSlice.windowInnerWidth);
    const data = [
        {
            image: artMasherDesImg,
            url: `/showcase/${ARTMASHER}`,
            title: 'Art Masher',
        },
        {
            image: vyrobyDesImg,
            url: `/showcase/${VYROBY}`,
            title: 'Vyroby',
        },
    ]
    const data2 = [
        
        {
            image: proCoachDesImg,
            url: `/showcase/${PROCOACH}`,
            title: 'Pro Coach',
        },
        {
            image: vulcanDesImg,
            url: `/showcase/${VULCAN}`,
            title: 'Vulcan',
        },
    ]
    
    return (
        <div className='home-view-wrap'>
            <PageMeta {...pageMeta['UserView']} />
            <div className='home-view container'>
                <div className='home-view__main-wrap'>
                    <h1 className="home-view__main-text">
                        Revolution Development: Code-Free, Stress-Free
                        <span className='home-view__main-text-dots-wrap'>
                            <span className='home-view__main-text-dots'>
                                .
                                <span className="emoji-container">
                                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotating-emoji">
                                        <rect x="8" y="2" width="3.99585" height="3.99585" rx="1.99792" fill="#0F0F0F"/>
                                        <path d="M3.33203 10.80762L3.79128 12.2538C4.8956 15.7311 8.21277 18.0208 11.85576 17.8203L14.066 17.6986" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round"/>
                                        <rect x="16" y="8" width="3.99585" height="3.99585" rx="1.99792" fill="#0F0F0F"/>
                                    </svg>
                                </span>
                            </span>
                        </span>
                    </h1>
                </div>
                <h3 className='home-view__sub-title'>Services:</h3>
                <div className='home-view__text'>Unlock the boundless potential of software, your ultimate versatile tool. Empowering you to conquer a myriad of challenges, our solutions are engineered to be efficient, scalable, and adaptable. Committed to sustainability, we craft software that not only solves problems but also embraces responsibility, shaping a better future.</div>
                
                <div className='home-view__accordions-wrap'>
                    <AccordionBlock 
                        idScroll={SOFTWARE}
                        title="Software" 
                        content="We specialize in creating robust, scalable software solutions tailored to your business needs. From developing comprehensive web and mobile applications to integrating advanced technologies, our expertise covers a wide range of software development services to ensure seamless functionality and performance." 
                    />
                    <AccordionBlock 
                        idScroll={AINTELLIGENCE}
                        title="Artificial intelligence" 
                        content="Harness the power of AI to drive innovation and efficiency in your business. Our AI services include developing intelligent systems for data analysis, machine learning models, and AI-powered applications that provide valuable insights and automate complex processes, enhancing your operational capabilities." 
                    />
                    <AccordionBlock 
                        idScroll={BUSINESS}
                        title="Business services" 
                        content="Our business services are designed to optimize your operations and support growth. We offer strategic consulting, project management, and staff augmentation to help you streamline processes, improve productivity, and achieve your business goals with expert guidance and support." 
                    />
                    <AccordionBlock 
                        idScroll={CYBERSECURITY}
                        title="Cybersecurity" 
                        content="Protect your digital assets with our comprehensive cybersecurity solutions. We provide advanced security measures to safeguard your systems, data, and networks from threats and breaches. Our services include vulnerability assessments, threat detection, and incident response to ensure your information remains secure." 
                    />
                    <AccordionBlock 
                        idScroll={SERVICES}
                        title="Data services" 
                        content="Unlock the full potential of your data with our specialized data services. We offer data management, analytics, and visualization solutions to help you make informed decisions. Our expertise ensures that your data is accurate, accessible, and leveraged effectively to drive business success." 
                    />
                    <AccordionBlock 
                        idScroll={DESIGN}
                        title="UI/UX Design" 
                        content="Create exceptional user experiences with our UI/UX design services. We design intuitive, visually appealing interfaces that enhance user engagement and satisfaction. Our approach focuses on understanding user needs and crafting designs that provide seamless and enjoyable interactions across all platforms." 
                    />
                </div>

                <h3 className='home-view__sub-title'>Partners:</h3>
                <div className='home-view__text'>Our most compelling testimonials come from the people and companies we've had the privilege to work with. Their experiences serve as the truest measure of our success.</div>
                <div className='home-view__logo-img-wrap'>
                    <img 
                        className='home-view__logo-img' 
                        src={restackImg} 
                        alt='restack img' 
                    />
                    <img 
                        className='home-view__logo-img' 
                        src={payoneerImg} 
                        alt='google img' 
                    />
                    <img 
                        className='home-view__logo-img' 
                        src={hebronImg} 
                        alt='samsung img' 
                    />
                    <img 
                        className='home-view__logo-img' 
                        src={bsbrImg} 
                        alt='oracle img' 
                    />
                    <img 
                        className='home-view__logo-img' 
                        src={mainflowImg} 
                        alt='oracle img' 
                    />
                </div>
                <h3 className='home-view__sub-title'>Achievements:</h3>
                <div className='home-view__text'>WeNoCode boasts a proven track record of delivering innovative solutions that drive success, showcasing our commitmentto excellence in the IT industry.</div>
                <div className='home-view__logo-img-wrap'>
                    <img 
                        className='home-view__logo-achievements-img' 
                        src={clutchImg} 
                        alt='restack img'
                    />
                    <img 
                        className='home-view__logo-achievements-img' 
                        src={softwareImg} 
                        alt='software img'
                    />
                    <img 
                        className='home-view__logo-achievements-img home-view__logo-achievements-img--max-width' 
                        src={pLogoImg} 
                        alt='pLogo img'
                    />
                </div>
                <h3 className='home-view__sub-title'>Showcase:</h3>
                <div className='home-view__text'>Discover our showcase for success stories and innovative solutions.See how our expertise drives impactful results.</div>
                
                <div className='home-view__case--wrap'>
                    <div className='home-view__case-wrap'>
                        {
                            !!data?.length && data.map((el, index) => (
                                <NavLink 
                                    className={`home-view__case-img-wrap ${`float${(index % 4) + 1}`} ${(index % 2 && windowInnerWidth < 640) ? ' home-view__case-img--transform' : ''}`}
                                    to={el.url} 
                                    key={index}
                                >
                                    <img 
                                        className='home-view__case-img' 
                                        src={el.image} 
                                        alt="caseImg"
                                    />
                                    <h4 className='showcase-view__img-text'>{el.title}</h4>
                                </NavLink>
                            ))
                        }
                    </div>
                    <div className='home-view__case-wrap home-view__case-wrap--padding'>
                        {
                            !!data2?.length && data2.map((el, index) => (
                                <NavLink 
                                    className={`home-view__case-img-wrap ${`float${(index % 4) + 1}`} ${(index % 2 && windowInnerWidth < 640) ? ' home-view__case-img--transform' : ''}`}
                                    to={el.url} 
                                    key={index}
                                >
                                    <img 
                                        className='home-view__case-img' 
                                        src={el.image} 
                                        alt="caseImg"
                                    />
                                    <h4 className='showcase-view__img-text'>{el.title}</h4>
                                </NavLink>
                            ))
                        }
                    </div>
                </div>
                <div className='home-view__load-more-wrap'>
                    <NavLink className='home-view__load-more' to='/showcase'>
                        Load more
                    </NavLink>
                </div>
            </div>
            <FormBlock />
        </div>
    );
}

export default HomeView;

