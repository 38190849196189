import './FilterMenu.css';
import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AccordionFilters from '../AccordionFilters/AccordionFilters';
import { setIsOpenFilterMenu, setSelectedDirections, setSelectedTechLevels } from '../../store/homeSlice';
import closeIcon from '../../assets/closeImg.svg';
import { directionsArr, techLevelsArr } from '../../helpers/Config';

function FilterMenu() {

	const isOpenFilterMenu = useSelector(state => state.homeSlice.isOpenFilterMenu);
	const selectedDirections = useSelector(state => state.homeSlice.selectedDirections);
	const selectedTechLevels = useSelector(state => state.homeSlice.selectedTechLevels);
	const dispatch = useDispatch();

	const handleSetSelectedDirections = (updatedDirections) => {
		dispatch(setSelectedDirections(updatedDirections));
	}
	const handleSetSelectedTechLevels = (updatedTechLevels) => {
		dispatch(setSelectedTechLevels(updatedTechLevels));
	}

    return (
			<div className='filter_menu-wrap'>
				{
					isOpenFilterMenu && 
						<div className='filter_menu'>
							<h1 className='filter_menu-title'>Filter</h1>
							<img src={closeIcon} alt="closeIcon" className='filter_menu-close-icon' onClick={() => dispatch(setIsOpenFilterMenu(false))}/>
							<AccordionFilters title='Directions' options={directionsArr} selectedFilters={selectedDirections} setSelectedFilters={handleSetSelectedDirections}/>
							<AccordionFilters title='Tech levels' options={techLevelsArr} selectedFilters={selectedTechLevels} setSelectedFilters={handleSetSelectedTechLevels}/>
							<button className='filter_menu-btn' onClick={() => dispatch(setIsOpenFilterMenu(false))}>Filter</button>
						</div>
				}
			</div>
    );
}

export default memo(FilterMenu);