import React from "react";
import "../ResponseModal/ResponseModal.css";
import emailIcon from "../../assets/emailIcon.svg";

export default function ResponseModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="response-modal">
      <div className="response-modal__content">
        <img src={emailIcon} alt="emailIcon" className="response-modal__img"/>
        <p className="response-modal__text">We'll contact you soon</p>
        <button className="response-modal__button" onClick={onClose}>
          Continue
        </button>
      </div>
    </div>
  );
}
