import './App.css';
import { Suspense } from 'react';
import {
    Route,
    Routes
} from 'react-router';
import Layout from './Layout/Layout';
import { Navigate } from 'react-router-dom';
import NotFoundView from './views/NotFoundView/NotFoundView';
import HomeView from './views/HomeView/HomeView';
import ShowMessage from './components/ShowMessage/ShowMessage';
import ServicesView from './views/ServicesView/ServicesView';
import ShowcaseView from './views/ShowcaseView/ShowcaseView';
import PossibilitiesView from './views/PossibilitiesView/PossibilitiesView';
import DetailsView from './views/DetailsView/DetailsView';
import BlogsView from './views/BlogsView/BlogsView';
import ContactView from './views/ContactView/ContactView';
import { AIVSAI, ARTMASHER, DEVELOPMENT, EVENTBRIDGE, EXTO, GENERATEIMG, GENERATEVIDEO, PROCOACH, SHOPBUILDER, VULCAN, VYROBY, WRITEAPI, } from './helpers/Config';
import BlogView from './views/BlogView/BlogView';
import ProjectView from './views/ProjectView/ProjectView';
import 'swiper/css';
import 'swiper/css/pagination';

function App() {
    return (
        <div className="app">
            {/* <Suspense fallback={<Preloader />}> */}
            <Suspense>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomeView />}/>
                        <Route path="services" element={<ServicesView />}/>
                        <Route path="showcase" element={<ShowcaseView />}/>
                        <Route path={`showcase/${ARTMASHER}`} element={<ProjectView project={ARTMASHER} />}/>
                        <Route path={`showcase/${VYROBY}`} element={<ProjectView project={VYROBY} />}/>
                        <Route path={`showcase/${PROCOACH}`} element={<ProjectView project={PROCOACH} />}/>
                        <Route path={`showcase/${VULCAN}`} element={<ProjectView project={VULCAN} />}/>
                        <Route path={`showcase/${SHOPBUILDER}`} element={<ProjectView project={SHOPBUILDER} />}/>
                        <Route path={`showcase/${EVENTBRIDGE}`} element={<ProjectView project={EVENTBRIDGE} />}/>
                        <Route path={`showcase/${EXTO}`} element={<ProjectView project={EXTO} />}/>
                        <Route path="possibilities" element={<PossibilitiesView />}/>
                        <Route path="possibilities/details/:id" element={<DetailsView />}/>
                        <Route path="contact" element={<ContactView />}/>
                        <Route path="blogs" element={<BlogsView />}/>
                        <Route path={`blog/${WRITEAPI}`} element={<BlogView project={WRITEAPI} />}/>
                        <Route path={`blog/${DEVELOPMENT}`} element={<BlogView project={DEVELOPMENT} />} />
                        <Route path={`blog/${GENERATEIMG}`} element={<BlogView project={GENERATEIMG} />} />
                        <Route path={`blog/${AIVSAI}`} element={<BlogView project={AIVSAI} />} />
                        <Route path={`blog/${GENERATEVIDEO}`} element={<BlogView project={GENERATEVIDEO} />} />
                        <Route path="*" element={<Navigate to="not-found" />} />
                        <Route path="not-found" element={<NotFoundView />}/>
                    </Route>
                </Routes>
                <ShowMessage />
            </Suspense>
        </div>
  );
}

export default App;
