import { fetchRequest } from "../helpers/Utils";

export const getPossibilitiesByFilter = async (data) => {
    try{
        let res = await fetchRequest('POST', '/vacancies/filtered',data);
        return res;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }    
}

export const getPossibilitiesById = async (id) => {
    try{
        let res = await fetchRequest('GET', `/vacancies/${id}`);
        return res;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }    
}

export const sendApplyForm = async (formData) => {
    return fetch(process.env.REACT_APP_BASE_URL + "/applications", {
      method: "POST",
      body: formData,
    }).then((response) => response.json());
  };
  