import React, { useState, useEffect } from 'react'
import './PossibilitiesView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import searchIcon from '../../assets/searchIcon.svg';
import filterListIcon from '../../assets/filterListIcon.svg';
import closeImg from '../../assets/closeImg.svg';
import AccordionFilters from '../../components/AccordionFilters/AccordionFilters';
import { setIsOpenFilterMenu } from '../../store/homeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPossibilitiesByFilter } from '../../services/possibilities';
import { directionsArr, techLevelsArr } from '../../helpers/Config';
import { setSelectedDirections, setSelectedTechLevels } from '../../store/homeSlice';

export default function PossibilitiesView() {

	const isOpenFilterMenu = useSelector(state => state.homeSlice.isOpenFilterMenu);
	const selectedDirections = useSelector(state => state.homeSlice.selectedDirections);
	const selectedTechLevels = useSelector(state => state.homeSlice.selectedTechLevels);
	const [isSearchVisible, setIsSearchVisible] = useState(false);
	const [possibilitiesArr, setPossibilitiesArr] = useState([]);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const handleOpenFilterMenu = () => {
		dispatch(setIsOpenFilterMenu(true));
	}

  const toggleSearchInput = () => {
    setIsSearchVisible(!isSearchVisible); 
  };

	const navigateToDetails = (id) => {
		console.log('id: ', id);
		navigate(`/possibilities/details/${id}`);
	}

	useEffect(() => {	
		handleGetPossibilitiesByFilter()
	}, [selectedDirections, selectedTechLevels]);

	const handleSetSelectedDirections = (updatedDirections) => {
		dispatch(setSelectedDirections(updatedDirections));
	};

	const handleSetSelectedTechLevels = (updatedTechLevels) => {
		dispatch(setSelectedTechLevels(updatedTechLevels));
	};
	

	const handleGetPossibilitiesByFilter = async () => {
		let data = {
			"directions": selectedDirections,
			"tech_levels": selectedTechLevels
			
		}
			let res = await getPossibilitiesByFilter(data);
			if(res){
				setPossibilitiesArr(res);
			}else{
				console.log('error');
			}

	}
	
  return (
    <div className='showcase-view-wrap'>
			<PageMeta {...pageMeta['PossibilitiesView']} />
			<div className='possibilities-view container'>
				<div className='possibilities-view__main-wrap'>
					<div className='possibilities-view__main-text-wrap'>
						<h2 className='possibilities-view__main-text'>Possibilities</h2>
						<p className='possibilities-view__main-sub-text'>
								DBe a Part of Our Journey — 
								Where Growth and Innovation 
								Go Hand in Hand
						</p>
					</div>
				</div>
			</div>
			<div className='possibilities-view__jobs-wrap container'>
				<div className='possibilities-view__jobs'>
					<div className="possibilities-view__jobs-input-container">
						<img src={searchIcon} alt="searchIcon" className='possibilities-view__jobs-input-icon'/>
						<input type="text" placeholder="Search" className="possibilities-view__jobs-input" />
					</div>
					<AccordionFilters title='Directions' options={directionsArr} selectedFilters={selectedDirections} setSelectedFilters={handleSetSelectedDirections} className='accordion-jobs-wrap__active'/>
					<AccordionFilters title='Tech levels' options={techLevelsArr} selectedFilters={selectedTechLevels} setSelectedFilters={handleSetSelectedTechLevels} className='accordion-jobs-wrap__active'/>
					<h4 className='possibilities-view__jobs-title'>{possibilitiesArr.length} Jobs</h4>
					<img src={filterListIcon} alt="filterListIcon" className='possibilities-view__jobs-filter-icon'/>
				</div>
				<div className='possibilities-view__jobs-mobile'>
					<div className="possibilities-view__jobs-input-container">
						<img
							src={searchIcon}
							alt="searchIcon"
							className='possibilities-view__jobs-input-icon'
							onClick={toggleSearchInput}
						/>
						{isSearchVisible && (
							<input
								type="text"
								placeholder="Search"
								className={`possibilities-view__jobs-input ${isSearchVisible ? 'open' : ''}`}
							/>
						)}
						{isSearchVisible && (
							<img src={closeImg} alt="closeImg" className='possibilities-view__jobs-input-close' onClick={toggleSearchInput}/>
						)}
					</div>
					<div className='possibilities-view__jobs-title-wrap'>
						{ isSearchVisible === false &&  <h4 className='possibilities-view__jobs-title'>{possibilitiesArr.length} Jobs</h4>}
						<img src={filterListIcon} alt="filterListIcon" className='possibilities-view__jobs-filter-icon' onClick={handleOpenFilterMenu}/>
					</div>
				</div>
				<div className='possibilities-view__list'>
					{possibilitiesArr.map((item) => (
						<div className='possibilities-view__list-item' key={item._id} onClick={()=> navigateToDetails(item._id)}>
							<div className='possibilities-view__list-item-title-wrap'>
								<p className='possibilities-view__list-item-title'>{item.title}</p>
							</div>
							<div className='possibilities-view__list-item-text-wrap'>
								<p className='possibilities-view__list-item-text'>{item.location}</p>
								<p className='possibilities-view__list-item-text'>{item.technicalLevel}</p>
								<p className='possibilities-view__list-item-text'>{item.direction}</p>
							</div>
						</div>
					))}
				</div>
			</div>
    </div>
  )
}
