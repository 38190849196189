export let SOFTWARE = 'software';
export let AINTELLIGENCE = 'artificial_intelligence';
export let BUSINESS = 'business_services';
export let CYBERSECURITY = 'cybersecurity';
export let SERVICES = 'data_services';
export let DESIGN = 'design';
export let WRITEAPI = 'write_api';
export let ARTMASHER = 'art-masher';
export let VYROBY = 'vyroby';
export let PROCOACH = 'pro_coach';
export let VULCAN = 'vulcan'
export let SHOPBUILDER = 'shop_builder'
export let EVENTBRIDGE = 'event_bridge'
export let EXTO = 'exto'
export let DEVELOPMENT = 'development'
export let GENERATEIMG = 'generating_images'
export let AIVSAI = 'ai_vs_ai'
export let GENERATEVIDEO = 'generating_video'

export let techLevelsArr = [
	{ id: 9, value: "Trainee", title: "Trainee" },
	{ id: 10, value: "Junior", title: "Junior" },
	{ id: 11, value: "Senior", title: "Senior" },
	{ id: 12, value: "Lead", title: "Lead" },
	{ id: 13, value: "CTO", title: "CTO" },
];

export let directionsArr = [
	{ id: 1, value: "Development", title: "Development" },
	{ id: 2, value: "DevOps & Cloud Engineering", title: "DevOps & Cloud Engineering" },
	{ id: 3, value: "Data Science & AI/ML", title: "Data Science & AI/ML" },
	{ id: 4, value: "Cybersecurity", title: "Cybersecurity" },
	{ id: 5, value: "QA", title: "QA" },
	{ id: 6, value: "UI/UX Design & Product Design", title: "UI/UX Design & Product Design" },
	{ id: 7, value: "Project Management", title: "Project Management" },
	{ id: 8, value: "Blockchain & Web3 Development", title: "Blockchain & Web3 Development" },
]