import React, { useState, useRef, useEffect } from 'react'
import './DetailsView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import ApplyForm from '../../components/ApplyForm/ApplyForm';
import telegramIcon from '../../assets/telegramIcon.svg';
import faceboockIcon from '../../assets/facebookImg.svg';
import linkedInIcon from '../../assets/linkedinImg.svg';
import { useParams } from 'react-router-dom';
import { getPossibilitiesById } from '../../services/possibilities';

export default function DetailsView() {

	const applyFormRef = useRef(null);
	const [showSocialIcons, setShowSocialIcons] = useState(false);
	const [deteilsObj, setDetailsObj] = useState({});
	const currentURL = window.location.href;
	const {id} = useParams();

	useEffect(() => {
		if(id){
			handleGetPossibilitiesById(id);
		}else{
			console.log('error');
		}
	}, [id]);

	const handleGetPossibilitiesById = async (id) => {
		let res = await getPossibilitiesById(id);
		if(res){
			setDetailsObj(res);
		}else{
			setDetailsObj({});
		}
	}

	const renderList = (text) => {
    if (!text) return null; 
    return text.split('\n').map((item, index) => (
      <p key={index}>{item}</p>
    ));
};


	const handleScrollToForm = () => {
    if (applyFormRef.current) {
      applyFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleShareClick = () => {
    setShowSocialIcons(prevState => !prevState);
  };
	
  return (
    <div className='details-view-wrap'>
			<PageMeta {...pageMeta['DetailsView']} />
			<div className='details-view container'>
				<div className='details-view container'>
					<div className='details-view__main-wrap'>
						<div className='details-view__main-text-wrap'>
							<h2 className='details-view__main-text'>{deteilsObj.title}</h2>
						</div>
					</div>
				</div>
				<div className='details-btn-wrap'>
				<div className="share-btn-wrap">
					<button className="share-btn" onClick={handleShareClick}>Share</button>
					<div className={`share-social-wrap ${showSocialIcons ? 'show' : ''}`}>
						<a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentURL)}`} target="_blank">
							<img className="share-social-icon" src={faceboockIcon} alt="Facebook" />
						</a>
						<a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentURL)}`} target="_blank">
							<img className="share-social-icon" src={linkedInIcon} alt="LinkedIn" />
						</a>
						<a href={`https://t.me/share/url?url=${encodeURIComponent(currentURL)}&text=Check this out!`} target="_blank">
							<img className="share-social-icon" src={telegramIcon} alt="Telegram" />
						</a>
					</div>
				</div>
					<button className='apply-btn' onClick={handleScrollToForm}>Apply</button>
				</div>
				<div className='details-view__section'>
					<h5 className='details-view__section-title'>About us:</h5>
					<p className='details-view__section-text'>
						{deteilsObj.aboutUs}
					</p>
				</div>
				<div className='details-view__section'>
					<h5 className='details-view__section-title'>About you:</h5>
					<div className='details-view__list'>
						{renderList(deteilsObj.aboutYou)}
					</div>
				</div>
				<div className='details-view__section'>
					<h5 className='details-view__section-title'>Your goal:</h5>
					<div className='details-view__list'>
						{renderList(deteilsObj.goal)}
					</div>
				</div>
				<div className='details-view__section'>
					<h5 className='details-view__section-title'>Together we will do:</h5>
					<div className='details-view__list'>
						{renderList(deteilsObj.togetherWeDo)}
					</div>
				</div>
				<div ref={applyFormRef}>
					<ApplyForm />
				</div>
			</div>
    </div>
  )
}
