import './ShowcaseView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import FormBlock from '../../components/FormBlock/FormBlock';
import caseImg1 from '../../assets/caseImg1.png';
import vulcanDesImg from '../../assets/vulcanDesImg.png';
import vyrobyDesImg from '../../assets/vyrobyDesImg.png';
import proCoachDesImg from '../../assets/proCoachDesImg.png';
import shopBuilderDesImage from '../../assets/shopBuilderDesImage.png';
import eventBridgeDesImage from '../../assets/eventBridgeDesImage.png';
import extoDesImage from '../../assets/extoDesImage.png';
import { NavLink } from 'react-router-dom';
import { ARTMASHER, PROCOACH, VYROBY, VULCAN, SHOPBUILDER, EVENTBRIDGE, EXTO } from '../../helpers/Config';
import { useState } from 'react';

function ShowcaseView() {
	const [activeFilter, setActiveFilter] = useState('Outsourced');

	const handleFilterClick = (filter) => {
		setActiveFilter(filter);
	};

	const showcaseData = [
		{ image: proCoachDesImg, url: `/showcase/${PROCOACH}`, title: 'Pro Coach', category: 'Internal' },
		{ image: caseImg1, url: `/showcase/${ARTMASHER}`, title: 'Art Masher', category: 'Outsourced' },
		{ image: vyrobyDesImg, url: `/showcase/${VYROBY}`, title: 'Vyroby', category: 'Outsourced' },
		{ image: shopBuilderDesImage, url: `/showcase/${SHOPBUILDER}`, title: 'Shop builder', category: 'Outsourced' },
		{ image: eventBridgeDesImage, url: `/showcase/${EVENTBRIDGE}`, title: 'Event bridge', category: 'Internal' },
		{ image: extoDesImage, url: `/showcase/${EXTO}`, title: 'Exto', category: 'Outsourced' },
		{ image: vulcanDesImg, url: `/showcase/${VULCAN}`, title: 'Vulcan', category: 'Outsourced' },
	];

	// Фільтрація по категорії
	const filteredData = showcaseData.filter((item) => item.category === activeFilter);

	// Розбиваємо на дві колонки
	const midIndex = Math.ceil(filteredData.length / 2);
	const leftColumn = filteredData.slice(0, midIndex);
	const rightColumn = filteredData.slice(midIndex);

	return (
		<div className='showcase-view-wrap'>
			<PageMeta {...pageMeta['ShowcaseView']} />
			<div className='showcase-view container'>
				<div className='showcase-view__main-wrap'>
					<div className='showcase-view__main-text-wrap'>
						<h2 className='showcase-view__main-text'>Showcase</h2>
						<p className='showcase-view__main-sub-text'>
							Discover our showcase for success stories and innovative solutions. 
							See how our expertise drives impactful results.
						</p>
					</div>
				</div>
				<div className='showcase-view__filters-wrap'>
					<div className='showcase-view__filters-text-wrap'>
						{['Outsourced', 'Internal'].map((filter) => (
							<p 
								key={filter}
								className={`showcase-view__filters-text ${activeFilter === filter ? 'active' : ''}`} 
								onClick={() => handleFilterClick(filter)}
							>
								{filter}
							</p>
						))}
					</div>
				</div>
				<div className='showcase-view__img---wrap'>
					<div className='showcase-view__img--wrap'>
						{leftColumn.map((el, index) => (
							<NavLink 
								className={`showcase-view__img-wrap float${(index % 4) + 1} ${index % 2 ? 'showcase-view__img-wrap--transform' : ''}`}
								to={el.url} 
								key={index}
							>
								<img className='showcase-view__img' src={el.image} alt={el.title} />
								<h4 className='showcase-view__img-text'>{el.title}</h4>
							</NavLink>
						))}
					</div>
					<div className='showcase-view__img--wrap showcase-view__img--wrap-padding'>
						{rightColumn.map((el, index) => (
							<NavLink 
								className={`showcase-view__img-wrap float${(index % 4) + 1} ${index % 2 ? 'showcase-view__img-wrap--transform' : ''}`}
								to={el.url} 
								key={index}
							>
								<img className='showcase-view__img' src={el.image} alt={el.title} />
								<h4 className='showcase-view__img-text'>{el.title}</h4>
							</NavLink>
						))}
					</div>
				</div>
			</div>
			<FormBlock />
		</div>
	);
}

export default ShowcaseView;
