import "./AccordionFilters.css";
import { memo, useState } from "react";

function AccordionFilters({ title, options, setSelectedFilters, selectedFilters }) {
  const [isActive, setIsActive] = useState(false);

  const handleCheckboxChange = (id) => {
    const updatedFilters = {
      ...selectedFilters,
      [id]: !selectedFilters[id],     
    };
    setSelectedFilters(updatedFilters);
  };
  

  return (
    <div className={`accordion-filters-wrap ${isActive ? "accordion-jobs-wrap__active" : ""}`}>
      <button className="accordion-filters" onClick={() => setIsActive(!isActive)}>
        <span className="accordion-filters__title">{title}</span>
        <span className="symbol">{isActive ? "\u2212" : "\u002B"}</span>
      </button>
      <div className="accordion-filters__panel" style={{ maxHeight: isActive ? "250px" : "0px" }}>
        {options.map((item) => (
          <div className="accordion-filters__item" key={item.id}>
            <p>{item.title}</p>
            <input
              type="checkbox"
              id={`checkbox-${item.id}`}
              className="accordion-filters__checkbox"
              checked={selectedFilters[item.value] || false}
              onChange={() => handleCheckboxChange(item.value)}
            />
            <label htmlFor={`checkbox-${item.id}`} className="accordion-filters__custom-checkbox"></label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(AccordionFilters);
