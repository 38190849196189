import "./ProjectView.css";
import { pageMeta } from "../../pageMeta";
import PageMeta from "../../components/PageMeta/PageMeta";
import FormBlock from "../../components/FormBlock/FormBlock";
import artMasherMobImg from "../../assets/artMasherMobImg.png";
import stackImg1 from "../../assets/stackImg1.svg";
import stackImg2 from "../../assets/stackImg2.svg";
import stackImg3 from "../../assets/stackImg3.svg";
import stackImg4 from "../../assets/stackImg4.svg";
import stackImg5 from "../../assets/stackImg5.svg";
import stackImg6 from "../../assets/stackImg6.svg";
import stackImg7 from "../../assets/stackImg7.svg";
import stackImg8 from "../../assets/stackImg8.svg";
import stackImg9 from "../../assets/stackImg9.svg";
import stackImg10 from "../../assets/stackImg10.svg";
import stackImg11 from "../../assets/stackImg11.svg";
import stackImg12 from "../../assets/stackImg12.svg";
import stackImg13 from "../../assets/stackImg13.svg";
import stackImg14 from "../../assets/stackImg14.svg";
import stackImg15 from "../../assets/stackImg15.svg";
import stackImg16 from "../../assets/stackImg16.svg";
import stackImg17 from "../../assets/stackImg17.svg";
import artMasherChallengeImg from "../../assets/artMasherChallengeImg.png";
import artMasherDescriptionImg from "../../assets/artMasherDescriptionImg.png";
import artMasherMob1 from "../../assets/artMasherMob1.png";
import artMasherMob2 from "../../assets/artMasherMob2.png";
import artMasherMob3 from "../../assets/artMasherMob3.png";
import alexanderImg from "../../assets/alexanderImg.png";
import vyrobyDesImg from "../../assets/vyrobyDesImg.png";
import vyobyChallengeImg from "../../assets/vyobyChallengeImg.png";
import vyrobyDescriptionImg from "../../assets/vyrobyDescriptionImg.png";
import artMasherDesImg from "../../assets/artMasherDesImg.png";
import vyrobySwiper1 from "../../assets/vyrobySwiper1.png";
import vyrobySwiper2 from "../../assets/vyrobySwiper2.png";
import vyrobySwiper3 from "../../assets/vyrobySwiper3.png";
import vyrobySwiper4 from "../../assets/vyrobySwiper4.png";
import vyrobyAdminSwiper1 from "../../assets/vyrobyAdminSwiper1.png";
import vyrobyAdminSwiper2 from "../../assets/vyrobyAdminSwiper2.png";
import vyrobyAdminSwiper3 from "../../assets/vyrobyAdminSwiper3.png";
import vyrobyAdminSwiper4 from "../../assets/vyrobyAdminSwiper4.png";
import proCoachCoachImg1 from "../../assets/proCoachCoachImg1.png";
import proCoachCoachImg2 from "../../assets/proCoachCoachImg2.png";
import proCoachCoachImg3 from "../../assets/proCoachCoachImg3.png";
import proCoachCoachImg4 from "../../assets/proCoachCoachImg4.png";
import proCoachDesImg from "../../assets/proCoachDesImg.png";
import proCoachDescriptionImg from "../../assets/proCoachDescriptionImg.png";
import proCoachChallengeImg from "../../assets/proCoachChallengeImg.png";
import vyrobyVideo from "../../assets/vyrobyVideo.mp4";
import proCoachVideo from "../../assets/proCoachVideo.mp4";
import bodyBuilderImg1 from "../../assets/bodyBuilderImg1.png";
import bodyBuilderImg2 from "../../assets/bodyBuilderImg2.png";
import bodyBuilderImg3 from "../../assets/bodyBuilderImg3.png";
import bodyBuilderImg4 from "../../assets/bodyBuilderImg4.png";
import bodyBuilderImg5 from "../../assets/bodyBuilderImg5.png";
import bodyBuilderImg6 from "../../assets/bodyBuilderImg6.png";
import bodyBuilderImg7 from "../../assets/bodyBuilderImg7.png";
import vulcanDesImg from "../../assets/vulcanDesImg.png";
import vulcanChallengeImg from "../../assets/vulcanChallengeImg.png";
import vulcanDescriptionImg from "../../assets/vulcanDescriptionImg.png";
import vulcanVideo from "../../assets/vulcanVideo.mp4";
import vulcanDataImg1 from "../../assets/vulcanDataImg1.png";
import vulcanDataImg2 from "../../assets/vulcanDataImg2.png";
import vulcanDataImg3 from "../../assets/vulcanDataImg3.png";
import vulcanDataImg4 from "../../assets/vulcanDataImg4.png";
import vulcanDataImg5 from "../../assets/vulcanDataImg5.png";
import vulcanDataImg6 from "../../assets/vulcanDataImg6.png";
import vulcanDataImg7 from "../../assets/vulcanDataImg7.png";
import shopBuilderDesImage from "../../assets/shopBuilderDesImage.png";
import shopBuilderDescriptionImg from "../../assets/shopBuilderDescriptionImg.png";
import shopBuilderChallengeImg from "../../assets/shopBuilderChallengeImg.png";
import shopBuilderDataImg1 from "../../assets/shopBuilderDataImg1.png";
import shopBuilderDataImg2 from "../../assets/shopBuilderDataImg2.png";
import shopBuilderDataImg3 from "../../assets/shopBuilderDataImg3.png";
import shopBuilderCustomersImg from "../../assets/shopBuilderCustomersImg.png";
import shopBuilderIncomeImg from "../../assets/shopBuilderIncomeImg.png";
import eventBridgeDesImage from "../../assets/eventBridgeDesImage.png";
import eventBridgeDescriptionImg from "../../assets/eventBridgeDescriptionImg.png";
import eventBridgeChallengeImg from "../../assets/eventBridgeChallengeImg.png";
import eventBridgeCreateImg1 from "../../assets/eventBridgeCreateImg1.png";
import eventBridgeCreateImg2 from "../../assets/eventBridgeCreateImg2.png";
import eventBridgeManagementImg1 from "../../assets/eventBridgeManagementImg1.png";
import eventBridgeManagementImg2 from "../../assets/eventBridgeManagementImg2.png";
import eventBridgeIncomeImg from "../../assets/eventBridgeIncomeImg.png";
import extoDesImage from "../../assets/extoDesImage.png";
import extoDescriptionImg from "../../assets/extoDescriptionImg.png";
import extoChallengeImg from "../../assets/extoChallengeImg.png";
import extoCreateImg1 from "../../assets/extoCreateImg1.png";
import extoCreateImg2 from "../../assets/extoCreateImg2.png";
import extoPlatformImg from "../../assets/extoPlatformImg.png";
import extoPlatformImg2 from "../../assets/extoPlatformImg2.png";
import extoControlImg1 from "../../assets/extoControlImg1.png";
import extoControlImg2 from "../../assets/extoControlImg2.png";
import extoControlImg3 from "../../assets/extoControlImg3.png";
import extoControlImg4 from "../../assets/extoControlImg4.png";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SteckPopover from "../../components/SteckPopover/SteckPopover";
import {
  ARTMASHER,
  PROCOACH,
  VYROBY,
  VULCAN,
  SHOPBUILDER,
  EVENTBRIDGE,
  EXTO,
} from "../../helpers/Config";

function ProjectView({ project }) {
  const windowInnerWidth = useSelector(
    (state) => state.homeSlice.windowInnerWidth
  );
  const stackArr3 = [
    {
      image: stackImg1,
      text: "React js",
    },
    ...(project === EXTO
      ? [
          {
            image: stackImg15,
            text: "Express.js ",
          },
        ]
      : [
          {
            image: stackImg9,
            text: "Prisma",
          },
        ]),
    ...(project === EXTO
      ? [
          {
            image: stackImg16,
            text: "MongoDB",
          },
        ]
      : [
          {
            image: stackImg10,
            text: "NestJS",
          },
        ]),
    {
      image: stackImg2,
      text: "PostgreSQL",
    },
    ...(project === SHOPBUILDER
      ? [
          {
            image: stackImg13,
            text: "PlanetScale",
          },
        ]
      : []),
    {
      image: stackImg7,
      text: "Figma",
    },
    {
      image: stackImg14,
      text: "Node.js",
    },
    {
      image: stackImg11,
      text: "AWS (Amazon Web Services)",
    },
    ...(project === EXTO
      ? [
          {
            image: stackImg17,
            text: "Telegram API",
          },
        ]
      : [
          {
            image: stackImg12,
            text: "Stripe",
          },
        ]),
  ];

  const stackArr1 = [
    {
      image: stackImg1,
      text: "React js",
    },
    {
      image: stackImg2,
      text: "PostgreSQL",
    },
    {
      image: stackImg3,
      text: "Redux",
    },
    {
      image: stackImg4,
      text: "Material-UI",
    },
  ];
  const stackArr2 = [
    {
      image: stackImg5,
      text: "Flutter",
    },
    {
      image: stackImg6,
      text: "Firebase",
    },
    {
      image: stackImg7,
      text: "Figma",
    },
    {
      image: stackImg8,
      text: "Redis",
    },
  ];
  const dataSwiper = [
    {
      img: artMasherMob1,
    },
    {
      img: artMasherMob2,
    },
    {
      img: artMasherMob3,
    },
  ];
  const vyrobyArrSwiper1 = [
    {
      img: vyrobySwiper1,
    },
    {
      img: vyrobySwiper2,
    },
    {
      img: vyrobySwiper3,
    },
    {
      img: vyrobySwiper4,
    },
  ];
  const vyrobyArrSwiper2 = [
    {
      img: vyrobyAdminSwiper1,
    },
    {
      img: vyrobyAdminSwiper2,
    },
    {
      img: vyrobyAdminSwiper3,
    },
    {
      img: vyrobyAdminSwiper4,
    },
  ];
  const coachArr = [
    {
      img: proCoachCoachImg1,
    },
    {
      img: proCoachCoachImg2,
    },
    {
      img: proCoachCoachImg3,
    },
    {
      img: proCoachCoachImg4,
    },
  ];
  const bodyBuilderArr = [
    {
      img: bodyBuilderImg1,
    },
    {
      img: bodyBuilderImg2,
    },
    {
      img: bodyBuilderImg3,
    },
    {
      img: bodyBuilderImg4,
    },
    {
      img: bodyBuilderImg5,
    },
    {
      img: bodyBuilderImg6,
    },
    {
      img: bodyBuilderImg7,
    },
  ];
  const vulcanArr = [
    {
      img: vulcanDataImg1,
    },
    {
      img: vulcanDataImg2,
    },
    {
      img: vulcanDataImg3,
    },
    {
      img: vulcanDataImg4,
    },
    {
      img: vulcanDataImg5,
    },
    {
      img: vulcanDataImg6,
    },
    {
      img: vulcanDataImg7,
    },
  ];
  const shopBuilderArr = [
    {
      img: shopBuilderDataImg1,
    },
    {
      img: shopBuilderDataImg2,
    },
    {
      img: shopBuilderDataImg3,
    },
  ];

  const projectData = {
    //all fields are required
    [ARTMASHER]: {
      imageMob: artMasherMobImg,
      imageDes: artMasherDesImg,
      imageDescription: artMasherDescriptionImg,
      imageChallenge: artMasherChallengeImg,
      title: "ART MASHER",
      description:
        "LEV is a mobile app (iOS and Android) tailor-made for urban dog owners. It's an interactive map connecting pet parents with each other and helping them find dog-friendly places nearby while earning real-life rewards during their daily walks. Users can turn the rewards into discounts in partner businesses and create stronger social bonds within the community.",
      challengeText1:
        "Before coming to us, the client had already spent $200k on the project with another team, which didn't show any results. So, when the client approached us with this unique challenge, we knew we had to put our best paws forward.",
      challengeText2:
        "The task was to create an app connecting dog owners with a community of fellow enthusiasts and providing a one-stop shop for all aspects of dog ownership. But the challenge didn't end there. We also had to cater to the needs of advertisers, boosting the online presence of brick-and-mortar businesses and improving exposure for online ventures.",
      approachText1: (
        <ul>
          <li className="project-view__text-padding">
            During this project, we reached unmatched development speed and
            could deliver up to eight features per sprint. It allowed us to make
            an early market entry while the product didn\'t yet have direct
            competitors.
          </li>
        </ul>
      ),
      approachText2: (
        <ul>
          <li className="project-view__text-padding">
            To reach that velocity, we successfully implemented a tailored
            approach combining a well-set, experienced team, hyper-detailed
            planning, and pre-developed modules.
          </li>
        </ul>
      ),
      approachText3: (
        <ul>
          <li className="project-view__text-padding">
            The pre-developed modules allowed us to avoid reinventing the wheel
            while implementing standard functionality like the
            authorization/registration process or basic admin panel. Following
            this approach, we successfully optimized available resources and
            significantly cut development time.
          </li>
        </ul>
      ),
      resultText:
        "We wish to acknowledge the collaboration with WeNoCode in our recent staff augmentation initiative. They have demonstrated substantial proficiency by assisting us in the development of a new blockchain game intended for the AlienWorlds.com ecosystem.In addition to their software development expertise, WeNoCode also offered graphic design services, contributing significantly to the project's success. Based on their performance, we found their work to be of high quality, meeting our expectations. Therefore, we intend to maintain our business relationship with WeNoCode for future projects, acknowledging the value they bring to our operations.",
      resultName: "Alexander Grover,",
      resultPosition: "CEO, ReStack.AI",
    },
    [VYROBY]: {
      imageMob: vyrobyDesImg,
      imageDes: vyrobyDesImg,
      imageDescription: vyrobyDescriptionImg,
      imageChallenge: vyobyChallengeImg,
      title: "VYROBY",
      description:
        "VYROBY is a specialized furniture marketplace for home decor, where customers can purchase modern furniture directly from carefully selected, reliable local manufacturers in Ukraine, with the option for custom-made furniture to fit their design projects. The site attracts over 200,000 visitors monthly.",
      challengeText1:
        "Creating a robust and user-friendly marketplace that effectively connects customers with local furniture manufacturers, facilitates custom orders, and handles high traffic volumes while ensuring a smooth shopping experience.",
      // challengeText2: 'Our platform addresses this challenge by creating a marketplace that unites the finest furniture manufacturers in one place. We\'ve curated a diverse collection of beautifully crafted pieces, offering something for every taste and style. Whether you\'re looking to refresh a single room or redesign an entire office, our platform connects you with the perfect furniture to elevate your space.',
      approachText1: (
        <ul>
          <li>
            Technology Integration: Employed React.js for a dynamic front-end,
            Express.js, and Node.js for a scalable back-end, and MongoDB for
            flexible data management. Utilized AWS for cloud hosting to ensure
            performance and reliability.
          </li>
        </ul>
      ),
      approachText2: (
        <ul>
          <li>
            Design and UX: Used Figma to design an intuitive and aesthetically
            pleasing user interface that enhances the shopping experience and
            streamlines the process of finding and purchasing furniture.
          </li>
        </ul>
      ),
      approachText3: (
        <ul>
          <li>
            E-commerce Optimization: Integrated Google Merchant for enhanced
            product visibility and searchability, ensuring that users have easy
            access to the diverse range of furniture offerings.
          </li>
        </ul>
      ),
      approachText4:
        "Custom Solutions: Developed features to support custom furniture orders, allowing users to specify their design preferences and collaborate with manufacturers directly.",
      resultText1:
        "Successfully launched VYROBY, a specialized furniture marketplace that attracts over 200,000 visitors each month. The platform has been praised for its user-friendly interface, effective integration with local manufacturers, and streamlined custom ordering process, making it a top choice for modern home decor.",
      resultText2:
        "To reach that velocity, we successfully implemented a tailored approach combining a well-set, experienced team, hyper-detailed planning, and pre-developed modules.",
      resultName: "Alexander Grover,",
      resultPosition: "CEO, ReStack.AI",
    },
    [PROCOACH]: {
      imageMob: proCoachDesImg,
      imageDes: proCoachDesImg,
      imageDescription: proCoachDescriptionImg,
      imageChallenge: proCoachChallengeImg,
      title: "PRO COACH",
      description:
        "LEV is a mobile app (iOS and Android) tailor-made for urban dog owners. It's an interactive map connecting pet parents with each other and helping them find dog-friendly places nearby while earning real-life rewards during their daily walks. Users can turn the rewards into discounts in partner businesses and create stronger social bonds within the community.",
      challengeText1:
        "Before coming to us, the client had already spent $200k on the project with another team, which didn't show any results. So, when the client approached us with this unique challenge, we knew we had to put our best paws forward.",
      challengeText2:
        "The task was to create an app connecting dog owners with a community of fellow enthusiasts and providing a one-stop shop for all aspects of dog ownership. But the challenge didn't end there. We also had to cater to the needs of advertisers, boosting the online presence of brick-and-mortar businesses and improving exposure for online ventures.",
      approachText1: (
        <ul>
          <li className="project-view__text-padding">
            During this project, we reached unmatched development speed and
            could deliver up to eight features per sprint. It allowed us to make
            an early market entry while the product didn\'t yet have direct
            competitors.
          </li>
        </ul>
      ),
      approachText2: (
        <ul>
          <li className="project-view__text-padding">
            To reach that velocity, we successfully implemented a tailored
            approach combining a well-set, experienced team, hyper-detailed
            planning, and pre-developed modules.
          </li>
        </ul>
      ),
      approachText3: (
        <ul>
          <li className="project-view__text-padding">
            The pre-developed modules allowed us to avoid reinventing the wheel
            while implementing standard functionality like the
            authorization/registration process or basic admin panel. Following
            this approach, we successfully optimized available resources and
            significantly cut development time.
          </li>
        </ul>
      ),
      resultText1:
        "Successfully launched Pro Coach, a comprehensive fitness app that has conducted over 6,800 training sessions and built a community of over 1,000 coaches.",
      resultText2:
        "The platform has been well-received for its user-friendly interface, personalized guidance, and reliable performance, significantly enhancing the fitness journey for bodybuilders both at home and in the gym.",
      resultName: "Alexander Grover,",
      resultPosition: "CEO, ReStack.AI",
    },
    [VULCAN]: {
      imageMob: vulcanDesImg,
      imageDes: vulcanDesImg,
      imageDescription: vulcanDescriptionImg,
      imageChallenge: vulcanChallengeImg,
      title: "VULCAN",
      description:
        "Vulcan is a B2B product designed for large companies to audit their databases efficiently and securely. It leverages machine learning models built by WeNoCode OU to create a custom scoring system that evaluates the integrity of database tables. By analyzing old production databases, Vulcan identifies anomalies or inaccurate data, helping businesses resolve client issues and optimize database performance. The platform handles sensitive data with precision, making it a powerful tool for ensuring data accuracy and compliance.",
      challengeText1:
        "Big companies often face difficulties in auditing large, old production databases. This can lead to inefficiencies, unresolved issues for their clients, and potential data anomalies that compromise business operations.",
      approachText1:
        "We developed Vulcan, a B2B solution with a custom scoring system for auditing databases. Using an ML model built by WeNoCode OU, Vulcan automatically analyzes tables to detect anomaly data or inaccuracies. It integrates advanced technologies to work with sensitive data, ensuring both speed and accuracy in auditing.",
      resultText1:
        "Vulcan has empowered companies to resolve long-standing issues within their databases. The system efficiently identifies and reports anomalies, streamlining the auditing process and providing clear, actionable insights.",
    },
    [SHOPBUILDER]: {
      imageMob: shopBuilderDesImage,
      imageDes: shopBuilderDesImage,
      imageDescription: shopBuilderDescriptionImg,
      imageChallenge: shopBuilderChallengeImg,
      title: "SHOP BUILDER",
      description:
        "This platform empowers users to quickly create tailored landing pages or multipage websites for specific products. Designed to simplify e-commerce, it offers complete customization of the site’s look and functionality, allowing users to: Define site structure (number and type of pages).Personalize themes, colors, and branding elements, such as logos. Integrate payment systems that automatically calculate taxes and shipping costs. With minimal setup, users can focus solely on fulfilling orders while the platform handles site creation, payment processing, and pricing calculations.",
      challengeText1:
        "Creating and managing product-specific websites is often time-intensive and technically challenging for businesses. The goal was to build an intuitive platform that simplifies website creation, integrates payment and tax calculations, and streamlines the overall process for sellers. The platform needed to support scalability and offer an effortless user experience for both tech-savvy and non-technical users.",
      challengeText2:
        "The task was to create an app connecting dog owners with a community of fellow enthusiasts and providing a one-stop shop for all aspects of dog ownership. But the challenge didn't end there. We also had to cater to the needs of advertisers, boosting the online presence of brick-and-mortar businesses and improving exposure for online ventures.",
      approachText1:
        "We developed a feature-rich platform with a focus on automation, ease of use, and robust performance:",
      approachText2:
        "Frontend: Built an interactive and intuitive interface with React and Figma, allowing users to customize their sites effortlessly.",
      approachText3:
        "Backend: Used NestJS and NodeJS to build a scalable, modular backend architecture for seamless site creation, management, and payment integration.",
      approachText4:
        "Database: Leveraged Prisma and PostgreSQL for efficient data management, ensuring fast and reliable site performance.",
      approachText5:
        // "Payment Integration: Integrated DIAGONAL Payment, MicroPayment and PayTech for seamless checkout experiences, including automatic tax calculations and shipping cost estimation. Deployment: Deployed the platform on AWS, ensuring high availability, reliability, and global scalability.",
        "Payment Integration: Integrated DIAGONAL Payment, MicroPayment and PayTech for seamless checkout experiences, including automatic tax calculations and shipping cost estimation.",
      approachText6:
        "Deployment: Deployed the platform on AWS, ensuring high availability, reliability, and global scalability.",
      resultText1:
        "The platform revolutionized the way businesses create and manage product-specific websites. Sellers could configure their sites once and focus on receiving orders, while the platform automated pricing, tax, and shipping calculations.",
      resultText2:
        "The shop builder feature accelerated the creation of websites for multiple products, saving businesses significant time and effort. The platform has empowered sellers to scale their operations effortlessly, providing a modern, user-friendly solution for e-commerce.",
    },
    [EVENTBRIDGE]: {
      imageMob: eventBridgeDesImage,
      imageDes: eventBridgeDesImage,
      imageDescription: eventBridgeDescriptionImg,
      imageChallenge: eventBridgeChallengeImg,
      title: "EVENT BRIDGE",
      description:
        "Event Bridge is a cutting-edge platform designed to revolutionize event planning for organizers of all types—from small gatherings to large-scale occasions. Whether you're a book publisher hosting a poetry release or planning a corporate seminar, Event Bridge streamlines the entire process.",
      challengeText1:
        "Planning events, whether small gatherings or large-scale occasions, can often feel cumbersome and time-consuming. Event organizers, like book publishers hosting a poetry release, face challenges in creating appealing event pages, managing invitations, and handling ticket sales—all while ensuring financial transparency. A streamlined platform was needed to simplify event management and provide an intuitive user experience for organizers and attendees alike.",
      approachText1:
        "We developed Event Bridge as a comprehensive platform tailored to meet the unique needs of event planners:",
      approachText2:
        "Intuitive Event Creation: Using React and Figma, we crafted a sleek, user-friendly interface, enabling organizers to create polished event pages effortlessly. An AI-powered helper was integrated to refine event descriptions, ensuring maximum appeal and professionalism.",
      approachText3:
        "Streamlined Backend Operations: Built with NestJS and Prisma, the backend ensures efficient and secure management of event data, attendee lists, and operations.",
      approachText4:
        "PostgreSQL was employed as the primary database to handle data storage and ensure scalability.",
      approachText5:
        "Robust Payment System: Implemented integrations with Stripe to provide a secure payment and tax model for paid events. Automated fee deductions and streamlined withdrawal processes enhance financial transparency.",
      approachText6:
        "Scalable Infrastructure: Leveraging AWS for hosting and server operations, the platform ensures reliability and fast performance, handling high traffic with ease.",
      resultText1:
        "Event Bridge transformed event planning into a seamless experience. Organizers can now create professional event pages in minutes, attract attendees effortlessly, and manage ticketing with ease.",
      resultText2:
        "The platform's AI helper has received widespread acclaim for refining event descriptions and enhancing their appeal. Secure payment solutions and automated tax handling further empower event planners to focus on what matters most—their events.",
    },
    [EXTO]: {
      imageMob: extoDesImage,
      imageDes: extoDesImage,
      imageDescription: extoDescriptionImg,
      imageChallenge: extoChallengeImg,
      title: "EXTO",
      description:
        "Exto is an ad network designed for Telegram mini-apps. When users interact with these mini-apps, they watch ads to earn bonuses. Advertisers use the platform to create and manage ad campaigns, ensuring efficient ad delivery across the mini-app network while providing a seamless user experience.",
      challengeText1:
        "Exto needed to build a seamless advertising network within Telegram mini-apps, where users could watch ads in exchange for bonuses. The challenge was to create a platform that not only ensured a non-intrusive experience for users but also provided advertisers with intuitive and robust campaign management tools. Scalability and reliability were critical to meet the demands of a growing user base.",
      approachText1:
        "We designed and developed a comprehensive platform tailored to Exto's requirements, focusing on scalability, usability, and performance:",
      approachText2:
        "Frontend: Leveraged React and Figma to craft a user-friendly interface that delivers a smooth and responsive experience for both publishers and advertisers.",
      approachText3:
        "Backend: Built a scalable and robust backend using ExpressJS and the NodeJS Framework to manage ad operations, campaign creation, and user interactions seamlessly.",
      approachText4:
        "Database: Implemented PostgreSQL for reliable data storage and efficient retrieval of user activity and campaign performance metrics.",
      approachText5:
        "Payment Integration: Integrated a Crypto Wallet for secure and modern payment options, enabling advertisers to fund campaigns effortlessly.",
      approachText6:
        "Deployment: Deployed the platform on Amazon Web Services (AWS) to ensure reliability, high availability, and fast performance for users worldwide.",
      resultText1:
        "The Exto platform launched successfully, becoming an intuitive and scalable ad network within Telegram mini-apps. Users enjoyed a hassle-free bonus redemption process, while advertisers gained a powerful tool for creating and managing campaigns. ",
      resultText2:
        "With a reliable infrastructure and modern payment integrations, Exto positioned itself as a leading advertising solution within the Telegram ecosystem. In just the first two weeks, over 400,000 users interacted with ads on the platform.",
    },
  };
  const arrSwiper2 =
    project === VYROBY ? [...vyrobyArrSwiper2] : [...dataSwiper];

  return (
    <div className="project-view-wrap">
      <PageMeta {...pageMeta["ProjectView"]} />
      <div className="project-view__main-wrap">
        <img
          className="project-view__main-img"
          src={
            windowInnerWidth > 640
              ? projectData[project].imageDes
              : projectData[project].imageMob
          }
          alt="blog img"
        />
        <div className="container project-view__main-text-wrap">
          <h2
            className={`project-view__main-text ${
              project === VULCAN || project === SHOPBUILDER
                ? "project-view__main-text-black"
                : "project-view__main-text"
            }`}
          >
            {projectData[project].title}
          </h2>
        </div>
      </div>
      <div className="project-view container">
        <div className="project-view__description-wrap">
          <div className="project-view__description project-view__description-desktop">
            <h4 className="project-view__title">Description</h4>
            <p className="project-view__text">
              {projectData[project].description}
            </p>
            <img
              className="project-view__description-img"
              src={projectData[project].imageDescription}
              alt="description img"
            />
            <h5 className="project-view__sub-title">Tech stack</h5>
            {project === SHOPBUILDER ||
            project === EVENTBRIDGE ||
            project === EXTO ? (
              <>
                <div className="project-view__stack-wrap ">
                  {!!stackArr3?.length &&
                    stackArr3.map((item, index) => (
                      <SteckPopover
                        key={index}
                        image={item.image}
                        text={item.text}
                      />
                    ))}
                </div>
              </>
            ) : (
              <>
                {
                  windowInnerWidth < 640 ? (
                    <div className="project-view__stack-wrap ">
                      {!!stackArr1?.length &&
                        stackArr1.map((item, index) => (
                          <SteckPopover
                            key={index}
                            image={item.image}
                            text={item.text}
                          />
                        ))}
                      {!!stackArr2?.length &&
                        stackArr2.map((item, index) => (
                          <SteckPopover
                            key={index}
                            image={item.image}
                            text={item.text}
                          />
                        ))}
                    </div>
                  ) : (
                    <>
                      <div className="project-view__stack-wrap ">
                        {!!stackArr1?.length &&
                          stackArr1.map((item, index) => (
                            <SteckPopover
                              key={index}
                              image={item.image}
                              text={item.text}
                            />
                          ))}
                      </div>
                      <div className="project-view__stack-wrap ">
                        {!!stackArr2?.length &&
                          stackArr2.map((item, index) => (
                            <SteckPopover
                              key={index}
                              image={item.image}
                              text={item.text}
                            />
                          ))}
                      </div>
                    </>
                  )
                }
              </>
            )}
          </div>
          <img
            className="project-view__description-img--desktop"
            src={projectData[project].imageDescription}
            alt="description img"
          />
        </div>
        <div className="project-view__block-wrap">
          <div className="project-view__block">
            <h4 className="project-view__title">The challenge</h4>
            <p className="project-view__text">
              {projectData[project].challengeText1}
            </p>
          </div>
          <img
            className="project-view__block-img"
            src={projectData[project].imageChallenge}
            alt="img"
          />
        </div>
        <div className="project-view__approach">
          <h4 className="project-view__title">Our approach</h4>
          <div className="project-view__approach-text--wrap">
            <div className="project-view__approach-text-wrap">
              <p className="project-view__text ">
                {projectData[project].approachText1}
              </p>
              <p className="project-view__text ">
                {projectData[project].approachText2}
              </p>
              {(project === SHOPBUILDER ||
                project === EVENTBRIDGE ||
                project === EXTO) && (
                <p className="project-view__text ">
                  {projectData[project].approachText3}
                </p>
              )}
            </div>
            <div className="project-view__approach-text-wrap">
              {project === SHOPBUILDER ||
              project === EVENTBRIDGE ||
              project === EXTO ? (
                <>
                  <p className="project-view__text ">
                    {projectData[project].approachText4}
                  </p>
                  <p className="project-view__text ">
                    {projectData[project].approachText5}
                  </p>
                  <p className="project-view__text ">
                    {projectData[project].approachText6}
                  </p>
                </>
              ) : (
                <p className="project-view__text ">
                  {projectData[project].approachText3}
                </p>
              )}
            </div>
          </div>
        </div>
        {(project === SHOPBUILDER ||
          project === EVENTBRIDGE ||
          project === EXTO) && (
          <div className="project-view__result">
            <h4 className="project-view__title">The result</h4>
            <div className="project-view__result-text--wrap">
              <div className="project-view__result-text-wrap">
                <p className="project-view__text">
                  {projectData[project].resultText1}
                </p>
              </div>
              <p className="project-view__text project-view__text--last">
                {projectData[project].resultText2}
              </p>
            </div>
          </div>
        )}
        {project === SHOPBUILDER && (
          <>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">Shop creation</h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={shopBuilderArr[0].img} alt="vulcan-data-img" />
              <img src={shopBuilderArr[1].img} alt="vulcan-data-img" />
              <img src={shopBuilderArr[2].img} alt="vulcan-data-img" />
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">Customers</h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={shopBuilderCustomersImg} alt="vulcan-data-img" />
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">Income</h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={shopBuilderIncomeImg} alt="vulcan-data-img" />
            </div>
          </>
        )}
        {project === EVENTBRIDGE && (
          <>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">
              Create moments
            </h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={eventBridgeCreateImg1} alt="vulcan-data-img" />
              <img src={eventBridgeCreateImg2} alt="vulcan-data-img" />
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">
              Event management
            </h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={eventBridgeManagementImg1} alt="vulcan-data-img" />
              <img src={eventBridgeManagementImg2} alt="vulcan-data-img" />
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">
              Event income
            </h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={eventBridgeIncomeImg} alt="vulcan-data-img" />
            </div>
          </>
        )}
        {project === EXTO && (
          <>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">
              Find the right people
            </h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={extoCreateImg1} alt="vulcan-data-img" />
              <img src={extoCreateImg2} alt="vulcan-data-img" />
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">
              Platform owner?
            </h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={extoPlatformImg} alt="vulcan-data-img" />
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">
              Join us!
            </h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={extoPlatformImg2} alt="vulcan-data-img" />
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">
              Flight control system
            </h5>
            <div className="project-view__slider-desktop project-view__slider-desktop--flex">
              <img src={extoControlImg1} alt="vulcan-data-img" />
              <img src={extoControlImg2} alt="vulcan-data-img" />
              <img src={extoControlImg3} alt="vulcan-data-img" />
              <img src={extoControlImg4} alt="vulcan-data-img" />
            </div>
          </>
        )}
        {project === VYROBY && (
          <>
            <div className="project-view__result">
              <h4 className="project-view__title">Result</h4>
              <div className="project-view__result-text--wrap">
                <div className="project-view__result-text-wrap">
                  <p className="project-view__text">
                    {projectData[project].resultText1}
                  </p>
                </div>
                <p className="project-view__text project-view__text--last">
                  {projectData[project].resultText2}
                </p>
              </div>
            </div>
            <div className="project-view__video-container">
              <video
                className="project-view__result-video"
                autoPlay
                loop
                muted
                width="100%"
                height="100%"
                src={vyrobyVideo}
              >
                {" "}
                Your browser does not support the video tag.
              </video>
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">Customer</h5>
            {!!vyrobyArrSwiper1?.length && (
              <Swiper
                slidesPerView={
                  windowInnerWidth < 640
                    ? 1.35
                    : windowInnerWidth > 1024
                    ? 4
                    : 2.35
                }
                spaceBetween={20}
                grabCursor={true}
                pagination={{
                  clickable: true,
                }}
                className="project-view__slider"
              >
                {vyrobyArrSwiper1.map((el, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="project-view__slider-img"
                      src={el.img}
                      alt="slider-img"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">Admin</h5>
            {!!vyrobyArrSwiper2?.length && (
              <Swiper
                slidesPerView={
                  windowInnerWidth < 640
                    ? 1.35
                    : windowInnerWidth > 1024
                    ? 4
                    : 2.35
                }
                spaceBetween={20}
                grabCursor={true}
                pagination={{
                  clickable: true,
                }}
                className="project-view__slider"
              >
                {vyrobyArrSwiper2.map((el, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="project-view__slider-img"
                      src={el.img}
                      alt="slider-img"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </>
        )}
        {/* add proCoachVideo */}
        {project === PROCOACH && (
          <>
            <div className="project-view__result">
              <h4 className="project-view__title">Result</h4>
              <div className="project-view__result-text--wrap">
                <div className="project-view__result-text-wrap">
                  <p className="project-view__text">
                    {projectData[project].resultText1}
                  </p>
                </div>
                <p className="project-view__text project-view__text--last">
                  {projectData[project].resultText2}
                </p>
              </div>
            </div>
            <div className="project-view__video-container">
              <video
                className="project-view__result-video"
                autoPlay
                loop
                muted
                width="100%"
                height="100%"
                src={proCoachVideo}
              >
                {" "}
                Your browser does not support the video tag.
              </video>
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">Coach</h5>
            <div className="project-view__slider-desktop">
              {coachArr.map((el, index) => (
                <img
                  className="project-view__slider-img--desktop"
                  src={el.img}
                  alt="slider-img"
                />
              ))}
            </div>
            {coachArr?.length && (
              <Swiper
                slidesPerView={
                  windowInnerWidth < 640
                    ? 1.35
                    : windowInnerWidth > 1024
                    ? 4
                    : 2.35
                }
                spaceBetween={20}
                grabCursor={true}
                pagination={{
                  clickable: true,
                }}
                className="project-view__slider-mobile"
              >
                {coachArr.map((el, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="project-view__slider-img--mobile"
                      src={el.img}
                      alt="slider-img"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">Bodybuilder</h5>
            <div className="project-view__slider-bodybuilder--desktop">
              {bodyBuilderArr.map((el, index) => (
                <img
                  className="project-view__bodybuilder-img"
                  src={el.img}
                  alt="bodybuilder img"
                />
              ))}
            </div>
            {bodyBuilderArr?.length && (
              <Swiper
                slidesPerView={
                  windowInnerWidth < 640
                    ? 1.35
                    : windowInnerWidth > 1024
                    ? 4
                    : 2.35
                }
                spaceBetween={20}
                grabCursor={true}
                pagination={{
                  clickable: true,
                }}
                className="project-view__slider-bodybuilder--mobile"
              >
                {bodyBuilderArr.map((el, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="project-view__slider-bodybuilderimg"
                      src={el.img}
                      alt="slider-img"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </>
        )}
        {project === ARTMASHER && (
          <>
            <div className="project-view__swiper-container">
              {arrSwiper2?.length && (
                <Swiper
                  slidesPerView={
                    windowInnerWidth < 640
                      ? 1.35
                      : windowInnerWidth > 1024
                      ? 3
                      : 2.35
                  }
                  spaceBetween={20}
                  grabCursor={true}
                  pagination={{
                    clickable: true,
                  }}
                  className="project-view__slider"
                >
                  {arrSwiper2.map((el, index) => (
                    <SwiperSlide key={index}>
                      <img
                        className="project-view__slider-img"
                        src={el.img}
                        alt="slider-img"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
            <h4 className="project-view__title">The result</h4>
            <div className="project-view__review">
              <img
                className="project-view__review-img"
                src={alexanderImg}
                alt="img"
              />
              <div className="project-view__review-text--wrap">
                <p className="project-view__text project-view__review-text--desktop">
                  {projectData[project].resultText}
                </p>
                <div className="project-view__review-text-wrap">
                  <h6 className="project-view__review-title">
                    {projectData[project].resultName}
                  </h6>
                  <p className="project-view__review-text">
                    {projectData[project].resultPosition}
                  </p>
                </div>
              </div>
            </div>
            <p className="project-view__text project-view__review-text--mob">
              {projectData[project].resultText}
            </p>
          </>
        )}
        {project === VULCAN && (
          <>
            <div className="project-view__result-container">
              <h4 className="project-view__title">Result</h4>
              <p className="project-view__text project-view__text-width">
                {projectData[project].resultText1}
              </p>
            </div>
            <div className="project-view__video-container">
              <video
                className="project-view__result-video"
                autoPlay
                loop
                muted
                width="100%"
                height="100%"
                src={vulcanVideo}
              >
                {" "}
                Your browser does not support the video tag.
              </video>
            </div>
            <h5 className="project-view__swiper-title project-view__swiper-title--size2">Data analisys</h5>
            <div className="project-view__slider-desktop">
              <img src={vulcanDataImg1} alt="vulcan-data-img" />
              {vulcanArr.slice(1).map((el, index) => (
                <img
                  className="project-view__slider-img--desktop"
                  src={el.img}
                  key={index}
                  alt="slider-img"
                />
              ))}
            </div>
          </>
        )}
      </div>
      <FormBlock />
    </div>
  );
}

export default ProjectView;
