import './Header.css';
import { memo } from 'react';
import logoImg from '../../assets/logoImg.svg';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenMenu } from '../../store/homeSlice';
import facebookImg1 from '../../assets/facebookImg1.svg';
import instagramImg1 from '../../assets/instagramImg1.svg';
import linkedinImg1 from '../../assets/linkedinImg1.svg';
import youTubeImg1 from '../../assets/youTubeImg1.svg';

function Header() {
    const isOpenMenu = useSelector(state => state.homeSlice.isOpenMenu);
    const dispatch = useDispatch();

    const handleCloseMenu = () => {
        dispatch(setIsOpenMenu(false));
    }

    return (
        <header className='header-wrap'>
            {
                isOpenMenu && 
                    <div className='header__side-bar'>
                        <div className='header__side-bar-link-wrap'>
                            <div className='header__side-bar-link--wrap'>
                                <NavLink 
                                    className='header__side-bar-link' 
                                    onClick={handleCloseMenu} 
                                    to={'/'}
                                >
                                    Home
                                </NavLink>
                                <NavLink 
                                    className='header__side-bar-link' 
                                    onClick={handleCloseMenu} 
                                    to={'/services'}
                                >
                                    Services
                                </NavLink>
                                <NavLink 
                                    className='header__side-bar-link' 
                                    onClick={handleCloseMenu} 
                                    to={'/showcase'}
                                >
                                    Showcase
                                </NavLink>
                                <NavLink 
                                    className='header__side-bar-link' 
                                    onClick={handleCloseMenu} 
                                    to={'/possibilities'}
                                >
                                    Possibilities
                                </NavLink>
                                <NavLink 
                                    className='header__side-bar-link' 
                                    onClick={handleCloseMenu} 
                                    to={'/blogs'}
                                >
                                    Blog
                                </NavLink>
                                <NavLink 
                                    className='header__side-bar-link' 
                                    onClick={handleCloseMenu} 
                                    to={'/contact'}
                                >
                                    Contact us
                                </NavLink>
                            </div>
                            <div className='header__link-follow-links-wrap'>
                                <ul className='header__link-follow-links'>
                                    <li className='header__link-follow-link-item'>
                                        <a 
                                            className='header__link-follow-link'
                                            href='https://www.facebook.com/WeNoCode'
                                            target='_blank'
                                            rel='nofollow'
                                        >
                                            <img 
                                                className='header__link-follow-link-img'
                                                src={facebookImg1} 
                                                alt="facebook" 
                                            />
                                        </a>
                                    </li>
                                    <li className='header__link-follow-link-item'>
                                        <a 
                                            className='header__link-follow-link'
                                            href='https://www.instagram.com/wenocodeinc'
                                            target='_blank'
                                            rel='nofollow'
                                        >
                                            <img 
                                                className='header__link-follow-link-img'
                                                src={instagramImg1} 
                                                alt="instagram" 
                                            />
                                        </a>
                                    </li>
                                    <li className='header__link-follow-link-item'>
                                        <a 
                                            className='header__link-follow-link'
                                            href='https://www.linkedin.com/company/wenocode-inc'
                                            target='_blank'
                                            rel='nofollow'
                                        >
                                            <img 
                                                className='header__link-follow-link-img'
                                                src={linkedinImg1} 
                                                alt="linkedin" 
                                            />
                                        </a>
                                    </li>
                                    <li className='header__link-follow-link-item'>
                                        <a 
                                            className='header__link-follow-link'
                                            href='https://www.youtube.com/@wenocode'
                                            target='_blank'
                                            rel='nofollow'
                                        >
                                            <img 
                                                className='header__link-follow-link-img'
                                                src={youTubeImg1} 
                                                alt="youTube" 
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
            }
            <div className="header container">
                <NavLink
                    to='/'
                >
                    <img 
                        className='header__logo'
                        src={logoImg} 
                        alt="logo" 
                    />
                </NavLink>
                <button 
                    className={`header__menu ${isOpenMenu ? 'header__menu--active' : ''}`} 
                    onClick={() => dispatch(setIsOpenMenu(!isOpenMenu))}
                >
                    <div className="header__menu-bar1"></div>
                    <div className="header__menu-bar2"></div>
                    <div className="header__menu-bar3"></div>
                </button>
            </div>
        </header>
    );
}

export default memo(Header);